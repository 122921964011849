body{
  height: 100vh;
}
#root{
  height: 100%;
}
.App {
  text-align: center;
  height: 100%;
}

.App-logo {
  height: 4vmin;
  pointer-events: none;
}

.tree {
  position: relative;
  background: white;
  padding-top: 30px;
  margin-left: 1rem;
  font-family: 'Roboto Mono', monospace;
  font-size: 0.85rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  overflow: scroll;
}
.tree span {
  font-size: 13px;
  font-style: italic;
  letter-spacing: 0.4px;
  color: #a8a8a8;
}
.tree .fa-folder-open, .tree .fa-folder {
  color: #007bff;
}
.tree .fa-html5 {
  color: #f21f10;
}
.tree ul {
  padding-left: 5px;
  list-style: none;
}
.tree ul li {
  position: relative;
  padding-left: 15px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.tree ul li span{
  border-bottom: 1px solid gray;
  border-left: 1px solid gray;
  height: 1.5rem;
  width: 1rem;
  display: inline-block;
  margin-bottom: 0.2rem;
  margin-right: 0.2rem;
}

ul ul{
  border-left: 1px solid gray;
  margin-left: 1rem; 
}

.tree ul:first-child{
  border-left: none; 
}

/* .tree ul li:before {
  position: absolute;
  top: 15px;
  left: 0;
  width: 10px;
  height: 1px;
  margin: auto;
  content: '';
  background-color: #666;
}
.tree ul li:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 1px;
  height: 100%;
  content: '';
  background-color: #666;
} */
.tree ul li:last-child:after {
  height: 15px;
}
.tree ul a {
  cursor: pointer;
}
.tree ul a:hover {
  text-decoration: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
